import React from 'react'
import WeAreHiring from '../components/we-are-hiring'
import Layout from '../components/layout'

const HiringPage = () => (
  <Layout>
    <WeAreHiring />
  </Layout>
)

export default HiringPage;
