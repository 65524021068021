import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import FullPageBlock from '../full-page-block'
import Seat from './seat'
import ScrollAnimation from 'react-animate-on-scroll';

import "../animations.css"
import "animate.css/animate.min.css";


const Team = () => (
  <StaticQuery
    query={graphql`
      query EffyHQQuery {
        site {
          siteMetadata {
            effyHQ {
              teammates {
                name,
                title,
                twitter,
                linkedIn,
                profilePicture,
                info
              }
            }
          }
        }
      }
    `}
    render={data => (
      <FullPageBlock className="team-section" style={{ textColor: "inherit", bgColor: "#FFF", height: '112vh' }}>
        <div className="row no-margin justify-content-md-center">
          <div className="the-team">
            <div className="the-team-wrap">
              <hr style={{ backgroundColor: 'black' }} />
              <div className="text">
                The Team
              </div>
            </div>
          </div>
        </div>
        <div className="row no-margin justify-content-md-center home-team">
          <div style={{ textAlign: "left" }} className="effy-msg">
            We help each other to be great in what we do and we take <a target='_blank' class='text-links' href='https://www.culturemonkey.io/employee-engagement/employee-feedback-in-the-workplace-2022/'>employee feedback</a> seriously.
          </div>
        </div>
        <div className="row no-margin justify-content-md-center home-team">
          <div className="col-md-12 col-lg-10 col-xl-10">
            <div className="effy-table justify-content-md-center">
              <div className="row justify-content-md-center">
                <ScrollAnimation animateIn="subtleFadeInRight" duration="2" delay="100" animateOnce="true">
                  <Seat teammate={data.site.siteMetadata.effyHQ.teammates[0]} />
                </ScrollAnimation>
                <ScrollAnimation animateIn="subtleFadeInRight" duration="2" delay="200" animateOnce="true">
                  <Seat teammate={data.site.siteMetadata.effyHQ.teammates[1]} />
                </ScrollAnimation>
                <ScrollAnimation animateIn="subtleFadeInRight" duration="2" delay="300" animateOnce="true">
                  <Seat teammate={data.site.siteMetadata.effyHQ.teammates[2]} />
                </ScrollAnimation>
              </div>
              <div className="row justify-content-md-center">
                <ScrollAnimation animateIn="subtleFadeInRight" duration="2" delay="400" animateOnce="true">
                  <Seat teammate={data.site.siteMetadata.effyHQ.teammates[3]} />
                </ScrollAnimation>
                <ScrollAnimation animateIn="subtleFadeInRight" duration="2" delay="500" animateOnce="true">
                  <Seat teammate={data.site.siteMetadata.effyHQ.teammates[4]} />
                </ScrollAnimation>
                <ScrollAnimation animateIn="subtleFadeInRight" duration="2" delay="600" animateOnce="true">
                  <Seat teammate={data.site.siteMetadata.effyHQ.teammates[5]} />
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
        <div className="row no-margin justify-content-md-center home-team">
          <div className="effy-action">
            <ScrollAnimation animateIn="pulse" duration="0.75" delay="1500" animateOnce="true">
              Hover over and caress the happy faces to make their acquaintance.
            </ScrollAnimation>
          </div>
        </div>
      </FullPageBlock>
    )}
  />
)

export default Team
