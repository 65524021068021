import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import "../animations.css";
import "animate.css/animate.min.css";

const Header = () => (
  <header className="hiring-heading">
    <ScrollAnimation animateIn="subtleFadeInLeft" duration="1">
      <hr className="hr--black" />
    </ScrollAnimation>
    <ScrollAnimation animateIn="fadeIn" duration="1">
      <span className="hiring-heading-text">Current Openings</span>
    </ScrollAnimation>
  </header>
);

export default Header;
