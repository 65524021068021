import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import "../animations.css"

const HiringContact = () => (
  <section className="hiring__info__contact">
    <span className="spacer-small"/>
    <ScrollAnimation animateIn="subtleFadeInRight" duration="0.5" delay="800">
    <div className="hiring__info__contact">
      <div className="hiring__info__contact__primary-text">
        Did you find the right fit?
      </div>
      <a className="hiring__info__contact__button" href="https://effytech.typeform.com/to/QBVavl">Apply here</a>
    </div>
    </ScrollAnimation>
    <span className="spacer-large"/>
    <ScrollAnimation animateIn="subtleFadeInRight" duration="0.5" delay="800">
      <div className="hiring__info__contact">
        <span>Not too sure where you'll fit in?</span>
        <span>Reach out at jobs@effy.co.in</span>
      </div>
    </ScrollAnimation>
    <span className="spacer-small"/>
  </section>
);

export default HiringContact;
