const jobs = [
  {
    department: 'Engineering',
    description: 'Python, Django, Flask, PostgreSQL, Javascript, Git.',
    position: 'Full stack developer',
  },
  {
    department: 'Design',
    description: 'Design systems, branding, digital media, front end frameworks.',
    position: 'Head of Design',
  },
  {
    department: 'Marketing',
    description: 'Copy writing, social media management, SEO.',
    position: 'Intern',
  }
];

export default jobs;
