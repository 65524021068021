import React from 'react'
import Masthead from './Masthead';
import Content from './Content';
import Team from './team';

const WeAreHiring = () => (
  <>
    <Masthead />
    <Team />
    <Content />
  </>
);

export default WeAreHiring
