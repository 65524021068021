import React from 'react';
import PropTypes from 'prop-types';
import Job from './Job';

const Jobs = ({ jobs }) => (
  <section className="hiring__info__jobs">
    {
      jobs.map(
        ({ department, description, position }, index) => <Job department={department} description={description} position={position} index={index} />
      )
    }
  </section>
);

Jobs.propTypes = {
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      department: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
  })),
};

export default Jobs;
