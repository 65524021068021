import React from 'react'
import FullPageBlock from '../full-page-block'

const Masthead = () => (
  <FullPageBlock className="team-full-page" style={{ textColor : "white", bgColor : "#333333" }}>
    <div className="masthead-blur team-masthead-blur masthead-blue--we-are-hiring"></div>
    <div className="masthead-text team-masthead">
      <span className="masthead-text--small">
        Alma mater, GPA, glorified past in a Fortune 500 company doesn't excite us.
      </span>
      <br />
      Show your work not your résumé.
    </div>
  </FullPageBlock>
)

export default Masthead;
