import React from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';

import "../animations.css"

const Job = ({ department, description, position, index }) => (
  <ScrollAnimation animateIn="subtleFadeInUp" delay={index*200}>
    <section className="job">
      <span className="job__department">{department}</span>
      <span className="job__position">{position}</span>
      <span className="job__description">{description}</span>
    </section>
  </ScrollAnimation>
);

Job.propTypes = {
  department: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

export default Job;
