import React from 'react';

const Divider = () => (
  <div className="divider">
    <div className="divider_space" />
    <div className="divider__line" />
    <div className="divider_space" />
  </div>
);

export default Divider;
