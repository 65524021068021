import React from 'react';
import Header from './Header';
import Jobs from './Jobs';
import jobs from './jobs.config';
import HiringContact from './HiringContact';
import Divider from './Divider';

const Content = () => (
  <div className="hiring-block-container">
    <article className="hiring-block">
      <Header />
      <section className="hiring__info">        
        <Jobs jobs={jobs} />
        <Divider />
        <HiringContact />
      </section>
    </article>
  </div>
);

export default Content
