import React from 'react'
import utmishra from '../../images/utmishra.jpg'
import beck from '../../images/beck.jpg'
import jc from '../../images/jc.jpg'
import arihant from '../../images/arihant.jpg'
import laharika from '../../images/laharika.jpg'
import saga from '../../images/saga.jpg'
import linkedin from '../../images/linkedin.png'
import twitter from '../../images/twitter.svg'

class Seat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 'front'
    };
    this.flip = this.flip.bind(this);
  }

  flip() {
    this.setState({
      current: this.state.current === 'front' ? 'back' : 'front'
    });
  }

  render() {
    const teamMate = this.props.teammate;
    const pp = {
      utmishra: utmishra,
      beck: beck,
      jc: jc,
      arihant: arihant,
      laharika: laharika,
      saga: saga
    }
    console.log(teamMate);
    return (
      <div className="team-block" onTouchStart={this.flip}>
        <div className="flip-container">
          <div className="flipper">
            <div className="front">
              <img className="img-fluid" src={pp[teamMate.profilePicture]} alt={teamMate.name} />
            </div>
            <div className="back">
              <div className="member-name">
                {teamMate.name}
              </div>
              <div className="title">
                {teamMate.title}
              </div>
              <div className="info" dangerouslySetInnerHTML={{ __html: teamMate.info }} />
              <div className="links">
                {teamMate.twitter &&
                <a target="_blank" href={'https://twitter.com/' + teamMate.twitter} >
                  <img src={twitter} alt={teamMate.twitter} />
                </a>}
                <a target="_blank" href={'https://www.linkedin.com/in/' + teamMate.linkedIn} >
                  <img src={linkedin} alt={teamMate.linkedin} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Seat
